/**
 * Thank you page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"

/* Page function declaration */
const ThankyouPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageThankyou

  //CTA Footer page FAQ
  var ctaFooterFAQ= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "faq"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        noindex="noindex"
      />

      {/* Section Hero */}
      <Hero colorShape="green" classNamePage="general" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralThankYou} />

      {/* Main content */}
      <section className="main-section green">
        <div className="content-general text" dangerouslySetInnerHTML={{ __html:dataAcf.contentGeneralThankYou}} />
      </section>

      {/* Section CtaFooter */}
      {ctaFooterFAQ.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="faq" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="green" />
      })}
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "thank-you"}) {
      acfPageThankyou {
        contentGeneralThankYou
        titleGeneralThankYou
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`

/* Export page informations */
export default ThankyouPage
